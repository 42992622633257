import React from "react";
import Modal from "react-modal";
import GetQuoteForm from "../quote/GetQuoteHandyman";

const QuoteModal = ({
  modalIsOpen,
  closeModal,
  selectedService,
  handleSubmit,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Get a Free Quote Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          background: "linear-gradient(to bottom, #0090ea, #0064cc)",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          maxWidth: "600px",
          maxHeight: "1600px",
          overflowY: "auto",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          border: "none",
        },
      }}
    >
      <h5 className="banner-quote__h1">
        Get a Free Quote for {selectedService}
      </h5>
      <GetQuoteForm handleSubmit={handleSubmit} />
    </Modal>
  );
};

export default QuoteModal;
