import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import Reviews from '../reviews/ReviewsTV';
import Logo from "../../assets/furniture_installation_400/furniture_assembly_2730.webp";
import money_earn from "../../assets/image/roman_handyman.webp";
import GetQuoteFurnitureAssembly from "../quote/GetQuoteFurnitureAssembly";
import GetQuoteModal from "../quote/GetQuoteModalFurnitureAssembly";
import Modal from 'react-modal';

import ImageSliderBed from '../sliders/ImageSliderBed';
import ImageSliderCraft from '../sliders/ImageSliderCraft';
import ImageSliderDesk from '../sliders/ImageSliderDesk';
import ImageSliderDresser from '../sliders/ImageSliderDresser';
import ImageSliderVanity from '../sliders/ImageSliderVanity';
import ImageSliderKitchen from '../sliders/ImageSliderKitchen';
import ImageSliderIkea from '../sliders/ImageSliderIkea';
import ImageSliderEnterteinment from '../sliders/ImageSliderEnter';
import ImageSliderBbq from '../sliders/ImageSliderBbq';

const services = [
  { name: "Bed Assembly", link: "/bed-assembly" },
  { name: "Bench Assembly", link: "/bench-assembly" },
  { name: "Bookshelf Assembly", link: "/bookshelf-assembly" },
  { name: "Bunk Bed Assembly", link: "/bunk-bed-assembly" },
  { name: "Chair Assembly", link: "/chair-assembly" },
  { name: "Couch Assembly", link: "/couch-assembly" },
  { name: "Crib Assembly", link: "/crib-assembly" },
  { name: "Desk Assembly", link: "/desk-assembly" },
  { name: "Disassemble Furniture", link: "/disassemble-furniture" },
  { name: "Dresser Assembly", link: "/dresser-assembly" },
  { name: "Furniture Assembly", link: "/furniture-assembly" },
  { name: "Patio Furniture Assembly", link: "/patio-furniture-assembly" },
  { name: "Shelf Assembly", link: "/shelf-assembly" },
  { name: "Table Assembly", link: "/table-assembly" },
  { name: "Shed Assembly", link: "/shed-assembly" },
  { name: "Wardrobe Assembly", link: "/wardrobe-assembly" },
  { name: "IKEA Assembly Service", link: "/ikea-assembly" },
  { name: "Entertainment Center Assembly", link: "/entertainment-center-assembly" }
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function FurnitureAssembly() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (serviceName) => {
    setSelectedService(serviceName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setModalIsOpen(false);
  };

  const handleSubmit = (formData) => {
    // Здесь можно добавить логику отправки данных формы
    console.log('Form data submitted from GetQuoteForm:', formData);
    closeModal(); // Закрытие модального окна после отправки формы
  };

  const city = "Orange County";
  const { service } = useParams();
  const currentService = services.find(s => s.link === `/${service}`) || { name: 'Furniture Assembly' };

useEffect(() => {
    const handleAccordionClick = () => {
      const accordionItems = document.querySelectorAll(".accordion__item");

      accordionItems.forEach((item) => {
        const title = item.querySelector(".accordion__title");
        const content = item.querySelector(".accordion__content");

        title.addEventListener("click", function () {
          item.classList.toggle("active");
          content.classList.toggle("active");
          const plusIcon = title.querySelector(".plus-icon");
          plusIcon.style.transform = item.classList.contains("active")
            ? "rotate(45deg)"
            : "rotate(0deg)";
        });
      });
    };

    handleAccordionClick();

    return () => {
      const accordionItems = document.querySelectorAll(".accordion__item");

      accordionItems.forEach((item) => {
        const title = item.querySelector(".accordion__title");
        title.removeEventListener("click", handleAccordionClick);
      });
    };
  }, []);

  return (
    <section id="furniture_assembly">
      <div className="features__container container">

        <div className="mobile-container">
        <div className="image-container-earn">
          <img src={money_earn} alt="Description" className="rounded-image-earn" />
          <div className="centered-text-earn">Get $10 Off Your First Order!</div>

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Grab Your $10 Off
          </a>
          <br></br>
        </div>

        <div className="mobile-container">
          <ul class="furniture_skills_list">
            <li class="furniture_skills">Transparent Pricing</li>
            <li class="furniture_skills">Same Day Service</li>
            <li class="furniture_skills">Fast Turnaround Times</li>
          </ul>
        </div>

        </div>
          
        </div>




        <div className="mobile-container">
          <GetQuoteFurnitureAssembly serviceName={currentService.name} city={city} />
        </div>

        <div className="mobile-container">
          <div className="container_tv">
          <Reviews city={city} />
          <br />
          </div>
        </div>


        <div className="mobile-container">
          <h2 className="about__h1">
          Your Craftsman Roman
          </h2>
          <br />
          <ImageSliderCraft />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Book My Service
          </a>
          <br></br>
        </div>
          
        </div>


        <div className="mobile-container">
          <h2 className="about__h1">
          Bed Assembly Service
          </h2>
          <br />
          <ImageSliderBed />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>

        <div className="mobile-container">
          <h2 className="about__h1">
          Desk Assembly Service
          </h2>
          <br />
          <ImageSliderDesk />
         
          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>

        <div className="mobile-container">
          <h2 className="about__h1">
          Dresser Assembly Service
          </h2>
          <br />
          <ImageSliderDresser />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>


        <div className="mobile-container">
          <h2 className="about__h1">
          Vanity Table Assembly Service
          </h2>
          <br />
          <ImageSliderVanity />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>
        
        </div>



        <div className="mobile-container">
          <h2 className="about__h1">
          Dining Table Assembly Service
          </h2>
          <br />
          <ImageSliderKitchen />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>

        <div className="mobile-container">
          <h2 className="about__h1">
          IKEA Assembly Service
          </h2>
          <br />
          <ImageSliderIkea />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>

        <div className="mobile-container">
          <h2 className="about__h1">
          Entertainment Center Assembly Service
          </h2>
          <br />
          <ImageSliderEnterteinment />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>

        <div className="mobile-container">
          <h2 className="about__h1">
          BBQ Assembly Service
          </h2>
          <br />
          <ImageSliderBbq />
          <br />

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Get a Quote for This Job
          </a>
          <br></br>
        </div>

        </div>





          <div className="mobile-container">
          <ul class="furniture_skills_list">
    <li class="furniture_skills">Guaranteed On-Time Arrival</li>
    <li class="furniture_skills">Same-Day {city} Assembly Service Available</li>
    <li class="furniture_skills">Assembly Assurance: We'll Fix Any Issues Free of Charge</li>
    <li class="furniture_skills">VIP Assembly Service: Skip the Queue, Get Priority Service</li>
    <li class="furniture_skills">Unlock Great Prices: Save on Furniture Assembly for Your Move</li>
    <li class="furniture_skills">Price Negotiable: Let's Find the Best Deal Together</li>
      </ul>
    </div>






      <div className="mobile-container">

      
        <h2 className="features__h1" style={{ color: 'orange' }}>
          {city} {currentService.name} Service<p></p>
        </h2>
        {/* <h2 className="features__h1">
          Book Your Hassle-Free {currentService.name} Near Me Today!
        </h2> */}

        <div className="mobile-container-home">
      <div class="accordion">
        <div class="accordion__item">
          <div class="accordion__title">
          Where do we serve?
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          In the heart of {city}, we serve the entire area with a strong commitment to our local community.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Our Operating Hours
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Expert Team Available 8 AM - 8 PM Daily.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Understanding Our Pricing Strategy
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Each project is a unique masterpiece. Our quotes capture its essence with precision. Contact us to start your masterpiece – we’re flexible and ready to discuss terms that suit you.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Who are our clients?
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Serving both homes and businesses, we cater to a wide range of clients including homeowners, entrepreneurs, and property managers.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Do I need to be present or can access arrangements be made?
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Professional home services, completed efficiently—even when you're not home.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Trusted Technicians in Your Home
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Trust our skilled team with a decade of experience. Each technician undergoes rigorous Google Business vetting for your peace of mind. Equipped with top tools, they handle every job with professionalism and expertise, prioritizing safety protocols and customer confidentiality.
          </div>
        </div>
        <div class="accordion__item">
          <div class="accordion__title">
          Service Guarantees?
            <div class="plus-icon">+</div>
          </div>
          <div class="accordion__content">
          Championing American values of reliability and satisfaction, we guarantee 100% customer satisfaction.
          </div>
        </div>
        </div>
      </div>

        <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Text Us 24/7
          </a>
          <br></br>
        </div>
        </div>

        <div className="services-grid">
          {services.map((service, index) => (
            <a 
              key={index} 
              href={service.link} 
              className="service-button"
            >
              {service.name}
            </a>
          ))}
        </div>

        <br></br>
        <br></br>
        
        <div className="mobile-container">
        <div
          className="features__container container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <a href="/furniture-assembly-tools-for-handyman">
            <img
              src={Logo}
              alt="Cordless Drill"
              className="image_article"
            />
          </a>
          <a
            href="/furniture-assembly-tools-for-handyman"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              className="features__text"
              style={{ margin: "0", textAlign: "center" }}
            >
              <span style={{ textDecoration: "underline" }}>
                Click here to read more:
              </span>{" "}
              Seamless Assembly Solutions:<br />
              Professional Tools and Support for Every Project
            </p>
          </a>
        </div>
        <br />
        <p className="features__text">
            * Get the discount by leaving an honest review with a photo of the service!
        </p>
        </div>


        <br />
      </div>

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Get a Free Quote Modal"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            },
            content: {
              top: '50%',
              background: 'linear-gradient(to bottom, #0090ea, #0064cc)', // Исправлено здесь
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              maxWidth: '600px', // Можно настроить ширину модального окна
              maxHeight: '1600px', // Можно настроить максимальную высоту модального окна
              overflowY: 'auto', // Разрешить прокрутку содержимого, если оно не помещается
              borderRadius: '10px', // Закругление углов
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Тень
              border: 'none' // Убрать окантовку
            }
          }}
      >
        <h5 className="banner-quote__h1">GET YOUR BEST PRICE</h5>
        <GetQuoteModal handleSubmit={handleSubmit} />
      </Modal>
    </section>
  );
}

export default FurnitureAssembly;
