import React from "react";
import Slider from "react-slick";

import Google_Reviews from "../../assets/image/google_g_icon.webp";
import Yelp_Reviews from "../../assets/image/yelp_logo.webp";
import DefaultYelpAvatar from "../../assets/image/default.png";
import YelpFavicon from "../../assets/image/yelp_favicon.png";
import GoogleFavicon from "../../assets/image/favicon_google.png";

import Jessica from "../../assets/image/rewiews_handy/rewiews_icon_handy_1.png";
import David from "../../assets/image/rewiews_handy/rewiews_icon_handy_2.png";
import Laura from "../../assets/image/rewiews_handy/rewiews_icon_handy_3.png";
import John from "../../assets/image/rewiews_handy/rewiews_icon_handy_4.png";
import Michelle from "../../assets/image/rewiews_handy/rewiews_icon_handy_5.png";
import Chris from "../../assets/image/rewiews_handy/rewiews_icon_handy_6.png";
import Karen from "../../assets/image/rewiews_handy/rewiews_icon_handy_7.png";
import Mark from "../../assets/image/rewiews_handy/rewiews_icon_handy_8.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom arrow components
const PrevArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} slick-prev-tv`}
    style={style}
    onClick={onClick}
  />
);

const NextArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} slick-next-tv`}
    style={style}
    onClick={onClick}
  />
);

// Reviews data
const reviews = [
  {
    platformIcon: Google_Reviews,
    avatar: Jessica,
    name: "Jessica Miller",
    date: "July 15, 2024",
    stars: "★★★★★",
    review: `HubPro transformed our living room with a flawless paint job. The walls look perfect, and the attention to detail was impressive. They worked quickly and left the space spotless. I couldn’t be happier!`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: David,
    name: "David Anderson",
    date: "August 5, 2024",
    stars: "★★★★★",
    review: `Roman rebuilt our old deck, and it looks incredible now! He took time to discuss the design and materials and delivered excellent craftsmanship. The deck is now the centerpiece of our backyard. Highly recommend!`,
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Laura,
    name: "Laura S.",
    date: "September 8, 2024",
    stars: "★★★★★",
    review: `I called HubPro for a toilet replacement, and they did an outstanding job! Fast, clean, and professional. It’s rare to find someone this reliable. I’ll definitely call them for future plumbing work.`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Google_Reviews,
    avatar: John,
    name: "John Peterson",
    date: "October 3, 2024",
    stars: "★★★★★",
    review: `Roman helped with several small fixes around the house—squeaky doors, a broken drawer, and even a leaky faucet. He’s the kind of handyman everyone needs: polite, skilled, and efficient. Thanks, Roman!`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Michelle,
    name: "Michelle Adams",
    date: "October 17, 2024",
    stars: "★★★★★",
    review: `HubPro is my go-to for any handyman work. They’ve helped me with everything from assembling furniture to installing shelves. Always friendly, on time, and reasonably priced. Can’t recommend them enough!`,
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Chris,
    name: "Chris B.",
    date: "November 2, 2024",
    stars: "★★★★★",
    review: `Roman replaced the faucets in our bathroom, and they look amazing! He gave advice on the best options, and the work was done quickly and professionally. Great service from a skilled handyman!`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Karen,
    name: "Karen Hughes",
    date: "November 18, 2024",
    stars: "★★★★★",
    review: `HubPro made replacing our toilet and bathroom faucets a breeze. The process was seamless, and the results were perfect. I appreciate the professionalism and care they put into the job. Highly recommend!`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Mark,
    name: "Mark Johnson",
    date: "December 5, 2024",
    stars: "★★★★★",
    review: `Roman installed a ceiling fan in our living room, and it works perfectly. He made sure everything was secure and double-checked the wiring. It’s rare to find someone this thorough. Great experience!`,
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
];

function ReviewsHandyman({ city = "Orange County" }) {
  // const city = 'Orange County';

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="ReviewsTV">
      <div>
        <h2 className="reviews-tv__header">
          Real{" "}
          <span style={{ color: "orange", fontWeight: "bold" }}>5-Star </span>
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            <img
              src={YelpFavicon}
              alt="Yelp favicon"
              style={{
                width: "24px",
                height: "24px",
                marginLeft: "10px",
                marginRight: "3px",
              }}
            />
          </span>
          Yelp and
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            <img
              src={GoogleFavicon}
              alt="Google favicon"
              style={{
                width: "24px",
                height: "24px",
                marginLeft: "10px",
                marginRight: "3px",
              }}
            />
          </span>
          Google Reviews About Us from Your {city} Neighbors
        </h2>
        <Slider {...settings} className="reviews-tv__slider">
          {reviews.map((review, index) => (
            <div className="reviews-tv__review" key={index}>
              <img
                src={review.platformIcon}
                alt="Platform Icon"
                className="reviews-tv__icon"
              />
              <img
                src={review.avatar}
                alt={`${review.name} Avatar`}
                className="reviews-tv__avatar_2"
              />
              <div className="reviews-tv__author-wrapper">
                <a
                  className="reviews-tv__author"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={review.link}
                >
                  {review.name}
                </a>
                <div className="reviews-tv__date">{review.date}</div>
                <div className="reviews-tv__stars">{review.stars}</div>
              </div>
              <div className="reviews-tv__content">
                <p>{review.review}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ReviewsHandyman;
