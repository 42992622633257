import { useState } from "react";

const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (serviceName) => {
    setSelectedService(serviceName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setModalIsOpen(false);
  };

  return {
    modalIsOpen,
    selectedService,
    openModal,
    closeModal,
  };
};

export default useModal;
