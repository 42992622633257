import React from "react";
import Check from "../../assets/image/check.webp";
import Family from "../../assets/image/roman_handyman_logo.webp";


function About() {
  const city = "Orange County";

  return (
    <section class="about" id="about">
      <div class="about__container container">
      <h2 className="about__h1">HubPro.US Handyman Service is #1 in <span style={{ color: 'orange', fontWeight: 'bold' }}>{city}</span></h2>
      <br></br>
        <div class="about__p">
          <img src={Family}
               alt="Expertise Icon"
               style={{height: "300px", marginRight: "10px", borderRadius: "10px", float: "left"}}
          />
          <p> Welcome to <strong>HubPro.US</strong> - your go-to center for Handyman Service Hub right in the heart
            of {city}. I'm <strong>Roman</strong>, the proud owner of a <strong>local family business</strong> right
            here in Orange County. I personally stand behind the quality of every service we offer. With my engineering
            background and years of experience, I handle a majority of the work myself and personally select the team
            members. <br></br><br></br> I take a hands-on approach to every project, ensuring attention to detail and
            high-quality execution. Unlike aggregators, I focus on personalized service, building trust within
            our {city} community and supporting its prosperity, as our taxes stay local. <br></br><br></br>
            <strong>Important:</strong> We are not licensed contractors pursuant to Section 7027.2 of the Business and
            Professions Code of the State of California. </p>
          <div>
            <a
                class="get-quote__link action-button"
                id="topSmsButtonAboutPage"
                href="sms:+19494380343"
            >
              Text For Free Quote 24/7
            </a>
          </div>
          <div class="feature-list">
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>Quality Assurance</h3>
                <p>
                  We stand behind our work with a 100% satisfaction guarantee.
                  Your happiness is our priority, and we won't rest until you're
                  fully satisfied with the results.
                </p>
              </div>
            </div>
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>Customer Dedication</h3>
                <p>
                  At our company, we prioritize our customers above all else.
                  From the moment you contact us to the completion of the
                  project, expect personalized attention and dedicated support
                  every step of the way.
                </p>
              </div>
            </div>
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>Professional Excellence</h3>
                <p>
                  Our team consists of highly skilled technicians who are
                  committed to delivering professional and efficient service.
                  With a focus on detail and precision, we ensure that your
                  furniture assembly and TV mounting experience exceeds your
                  expectations.
                </p>
              </div>
            </div>
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>{city} Neighborly Care</h3>
                <p>
                  When you choose us, you're not just a customer – you're our
                  neighbor. We treat each project with the same care and
                  attention we would give to our own family and friends.
                </p>
              </div>
            </div>
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>Innovative Solutions</h3>
                <p>
                  Our experienced team specializes in providing innovative
                  solutions tailored to your unique needs. Whether you require
                  furniture assembly in a challenging space or want to enhance
                  your home entertainment setup with professional TV mounting,
                  we've got you covered.
                </p>
              </div>
            </div>
            <div class="feature-item">
              <div class="icon">
                <img src={Check} alt="Expertise Icon"/>
              </div>
              <div class="content-about">
                <h3>Uncompromising Quality</h3>
                <p>
                  We are committed to delivering results that exceed your
                  expectations. From ensuring a seamless assembly of your
                  furniture to providing optimal TV mounting solutions, we go
                  above and beyond to provide a seamless and satisfying
                  experience.
                </p>
              </div>
            </div>
          </div>
          <br></br><br></br>
          <p>
            <strong>Contact Us Today</strong> to experience the difference with
            Furniture Assembly & TV Mounting Service in {city}. We're here to
            transform your space into a comfortable and stylish haven that
            you'll love coming home to!
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
      <div>
        <a
            class="get-quote__link action-button"
            id="bottomSmsButtonAboutPage"
            href="sms:+19494380343"
        >
          Text Us 24/7
        </a>
      </div>
    </section>
  );
}

export default About;
