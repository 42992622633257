import React from "react";
import ReviewsHandy from "../../reviews/ReviewsHandyman";
import ImageSlider from "../../sliders/ImageSliderCraft";
import QuoteModal from "../../hooks/QuoteModal";
import { services } from "../../../data/servicesData";
import { handymanTopDescription } from "../../../data/handymanTopDescription";
import useModal from "../../hooks/useModal";
import HandymanServiceCard from "./HandymanServiceCard";
import _ from "lodash";
import RomanPhoto from "../../../assets/image/roman.jpg";

import ImageSliderFan from "../../sliders/ImageSliderFan";
import ImageSliderToilet from "../../sliders/ImageSliderToilet";
import ImageSliderToilet2 from "../../sliders/ImageSliderToilet2";
import ImageSliderPainting from "../../sliders/painting/ImageSliderPainting";
import ImageSliderTv from "../../sliders/tv/ImageSliderTVMountWork";

// Универсальная компонента для работы с любым городом
const HandymanService = ({ city }) => {
  const cityKey = _.camelCase(city);
  const descData = handymanTopDescription[cityKey];
  const { modalIsOpen, selectedService, openModal, closeModal } = useModal();

  const handleSubmit = (formData) => {
    console.log("Form data submitted from GetQuoteForm:", formData);
    closeModal();
  };

  const sliders = [
    <ImageSliderFan />,
    <ImageSliderToilet />,
    <ImageSliderToilet2 />,
    <ImageSliderPainting />,
    <ImageSliderTv />,
  ];

  return (
    <div>
      <section className="handyman-page">
        <div className="handyman-services">
          <div className="handyman-slider">
            <h2 className="about__h1">
              HubPro.US — Your Trusted Family Handyman in{" "}
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {city}
              </span>
            </h2>
            <br />

            <div className="responsive-container">
              <img
                src={RomanPhoto}
                alt="Roman's handyman"
                className="responsive-image"
              />
            </div>

            <div className="handyman_header_desc">
              <div dangerouslySetInnerHTML={{ __html: descData.description }} />
            </div>
          </div>

          <div className="container_tv_handy">
            <ReviewsHandy city={city} />
            <br />
          </div>

          <div className="handyman-slider">
            <h2 className="about__h1">
              Handyman Service in {city}: Browsing Through Our Portfolio of
              Services
            </h2>
            <br />
            <br />
            <ImageSlider />

            <br />
            <br />
          </div>

          {services.map((service, index) => (
            <React.Fragment key={index}>
              <HandymanServiceCard
                service={service}
                city={city}
                openModal={openModal}
              />
              {(index + 1) % 3 === 0 && (
                <div className="handyman-slider">
                  <h2 className="about__h1">
                    Handyman in {city}: Browsing Through Our Portfolio of
                    Services
                  </h2>
                  <br />
                  <br />
                  {sliders[Math.floor(index / 3) % sliders.length]}
                  <br />
                  <br />
                </div>
              )}
            </React.Fragment>
          ))}

          <div className="container_tv_handy">
            <ReviewsHandy city={city} />
            <br />
          </div>

          <div className="container_tv_handy">
            <h2 className="about__h1">
              Handyman Service is #1 in{" "}
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {city}
              </span>
            </h2>
            <br />
            <br />
            <p className="handyman_footer_desc">
              <div
                dangerouslySetInnerHTML={{ __html: descData.bottomDescription }}
              />
            </p>
            <br />
            <p className="about__h1">
              Why choose HubPro for your handyman needs?
            </p>
            <br />
            <div className="mobile-container">
              <ul className="furniture_skills_list">
                <li className="furniture_skills">
                  Fast Same-Day Service in {city} – We’re Ready When You Are!
                </li>
                <li className="furniture_skills">
                  VIP Priority Service – Skip the Line and Get the Best
                  Treatment
                </li>
                <li className="furniture_skills">
                  Guaranteed On-Time Arrival – We Respect Your Schedule
                </li>
                <li className="furniture_skills">
                  Expert Craftsmen for Any Task – From Simple Repairs to Complex
                  Projects
                </li>
              </ul>
            </div>
          </div>
        </div>
        <QuoteModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          selectedService={selectedService}
          handleSubmit={handleSubmit}
        />
      </section>
    </div>
  );
};

export default HandymanService;
