const articles = [
    {
        id: 1,
        slug: 'furniture-assembly-service',
        title: 'Upgrade Your Furniture Assembly Service Experience',
        content: 'When it comes to setting up a new home or upgrading your space with new furniture ...',
        date: '03-17-2024',
    },
    {
        id: 2,
        slug: 'bed-assembly-service',
        title: 'Professional Bed Assembly Services: Convenient, Efficient, and Reliable',
        content: 'Looking for a bed assembly service near you? Our professional bed installation ...',
        date: '03-12-2024',
    },
    {
        id: 3,
        slug: 'furniture-installation-service',
        title: 'Furniture Installation Service Near Me: Why Local Professionals Matter',
        content: 'Are you in need of professional furniture installation services? Whether you’ve just purchase ...',
        date: '12-11-2024',
    },
    {
        id: 4,
        slug: 'tv-mounting-service',
        title: 'Why Choose Professional TV Mounting Services?',
        content: 'When you need a reliable TV mounting service, it’s important to find a professional who can ...',
        date: '11-10-2024',
    },
    {
        id: 5,
        slug: 'flat-pack-services',
        title: 'Effortless Furniture Setup: Find the Best Flat Pack Assembly Services Near You',
        content: 'Find the Best Flat Pack Assembly Service Near Me: Flat Pack Cabinets, Furniture Assembly, and More ...',
        date: '08-11-2024',
    },
    {
        id: 6,
        slug: 'desk-assembly-service',
        title: 'Desk Assembly Service: Your Go-To Solution for Home and Office Furniture',
        content: 'Assembling a desk can be challenging, especially when dealing with multiple parts ...',
        date: '09-12-2024',
    },
    {
        id: 7,
        slug: 'murphy-bed-installation-service',
        title: 'Professional Murphy Bed Services: Installation and Assembly Near You',
        content: 'Are you looking to maximize your living space without sacrificing comfort and style ...',
        date: '09-28-2024',
    },
    {
        id: 8,
        slug: 'handyman-furniture-assembly',
        title: 'Finding the Perfect Handyman for Furniture Assembly Near You',
        content: 'Have you ever excitedly unpacked a new piece of furniture, only to be confronted with a mountain of parts ...',
        date: '04-07-2024',
    },
    {
        id: 9,
        slug: 'furniture-disassembly-service',
        title: 'Furniture Disassembly Service: Why You Need It and How It Works',
        content: 'Moving can be stressful, especially when you have bulky furniture that’s hard to move through ...',
        date: '04-07-2024',
    },
    {
        id: 10,
        slug: 'office-furniture-installation',
        title: 'Top-Notch Office Furniture Installation Services Near You',
        content: 'Finding reliable office furniture installation services near you can be a daunting task ...',
        date: '12-03-2024',
    },
    {
        id: 11,
        slug: 'flat-pack-service',
        title: 'Flat Pack Assembly Service: Your Guide to Quick and Easy Furniture Setup',
        content: 'Are you tired of spending hours struggling with the assembly of your flat pack furniture? Whether ...',
        date: '12-15-2024',
    },
    {
        id: 12,
        slug: 'handyman-service-near-me',
        title: 'Finding the Best Handyman Near Me: Your Go-To Guide for Handyman Services',
        content: 'Discover the best handyman service near you. From plumbing to home repairs ...',
        date: '10-15-2024',
    },
    {
        id: 13,
        slug: 'painters-service-near-me',
        title: 'Find the Best Painters Near Me for Your Home or Business',
        content: 'Are you searching for reliable painters near me to transform your space? Whether you ...',
        date: '11-14-2024',
    },
    {
        id: 14,
        slug: 'deck-repair-near-me',
        title: 'Expert Deck Repair Services Near You: Restoring Your Outdoor Space',
        content: 'Your deck is an essential part of your home outdoor space, providing a perfect spot for relaxation, entertaining ...',
        date: '08-26-2024',
    },
    {
        id: 15,
        slug: 'handyman-orange-county',
        title: 'Your Trusted Handyman Services in Orange County',
        content: 'Are you looking for reliable and affordable handyman services in Lake Forest, Yorba Linda, Buena Park, Rancho Santa Margarita, Irvine, or Tustin? ...',
        date: '08-26-2024',
    },
    {
        id: 16,
        slug: 'painting-services-near-me',
        title: 'Find Professional Painters Near You: Affordable and Reliable Painting Services',
        content: 'Are you looking for professional painters near me? Whether it\'s for interior or exterior painting, having the right team ...',
        date: '03-12-2024',
    },
    {
        id: 17,
        slug: 'repair-decks-near-me',
        title: 'Deck Repair Near Me: How to Find the Best Services for Your Home',
        content: 'Your deck is more than just an outdoor space; it’s an extension of your home. Whether you use ...',
        date: '02-11-2024',
    },
    {
        id: 18,
        slug: 'ikea-handyman-near-me',
        title: 'IKEA Handyman Services: Simplifying Your Furniture Assembly',
        content: 'When it comes to furnishing your home, IKEA is often the go-to choice for affordable and ...',
        date: '18-08-2024',
    },
    {
        id: 19,
        slug: 'best-furniture-assembly-service',
        title: 'Discover the Best Furniture Assembly Service for Your Needs',
        content: 'Are you tired of struggling with complicated furniture assembly instructions? Do you want to save ...',
        date: '6-01-2024',
    }
    ,
    {
        id: 20,
        slug: 'office-furniture-assembly-service',
        title: 'Your Go-To Guide for Furniture Assembly Services',
        content: 'When it comes to setting up your new space, whether it’s an office or a cozy patio, you want everything to be perfect ...',
        date: '3-11-2024',
    },
    {
        id: 21,
        slug: 'local-furniture-assembly-service',
        title: 'Discover the Best Furniture Assembly Services Near You',
        content: 'Are you tired of struggling with complicated furniture assembly instructions? Do you find yourself wishing for extra hands when ...',
        date: '1-01-2024',
    },
    {
        id: 22,
        slug: 'same-day-furniture-assembly',
        title: 'Your Guide to Furniture Assembly: Everything You Need to Know',
        content: 'Whether you’ve just bought a new piece of furniture or are considering a modular setup for your home or office ...',
        date: '7-09-2024',
    },
    {
        id: 23,
        slug: 'patio-furniture-assembly',
        title: 'Your Ultimate Guide to Furniture Assembly: Branch, Patio, and Office',
        content: 'Take your time to assemble each piece accurately, and don’t hesitate to call in the pros if you feel overwhelmed. Enjoy the process ...',
        date: '10-01-2024',
    },
    {
        id: 24,
        slug: 'murphy-bed-assembly-service',
        title: 'Everything You Need to Know About Murphy Bed Installation',
        content: 'If you\'re considering maximizing space in your home with a murphy bed, you’re not alone. Many homeowners and ...',
        date: '4-21-2024',
    },
    {
        id: 25,
        slug: 'handyman-assembly-near-me',
        title: 'The Ultimate Guide to Handyman Assembly: What You Need to Know',
        content: 'Have you ever found yourself staring at a pile of furniture parts, feeling overwhelmed at the thought of assembly? ...',
        date: '14-08-2024',
    },
    {
        id: 26,
        slug: 'best-handyman-service-near-me',
        title: 'Finding the Right Handyman Near You',
        content: 'Are you tired of looking around and wondering who the best handyman is in your neighborhood? Finding a reliable ...',
        date: '18-03-2024',
    },
    {
        id: 27,
        slug: 'local-handyman-services',
        title: 'Your Guide to Finding a Cheap Handyman Near Me: Affordable Solutions for Every Homeowner',
        content: 'Are you tired of tackling home repairs by yourself? Wondering where you can find ...',
        date: '05-05-2024',
    },
    {
        id: 28,
        slug: 'handyman-irvine',
        title: 'Your Go-To Handyman in Irvine, CA: Quality Service You Can Trust',
        content: 'When you’re looking for handyman services in Irvine, CA, it’s essential to find a reliable provider with ...',
        date: '22-06-2024',
    },
    {
        id: 29,
        slug: 'handyman-laguna-beach',
        title: 'Reliable Handyman Services in Laguna Beach, CA',
        content: 'When it comes to home maintenance and repairs, having a trustworthy handyman in Laguna Beach, CA ...',
        date: '30-09-2024',
    },
    {
        id: 30,
        slug: 'handyman-huntington-beach',
        title: 'Finding the Best Handyman in Huntington Beach, CA: Your Go-To Guide',
        content: 'When it comes to maintaining your home or tackling those lingering household ...',
        date: '12-12-2024',
    },
    {
        id: 31,
        slug: 'handyman-san-clemente',
        title: 'Your Go-To Handyman in San Clemente: Reliable, Affordable, and Always Available!',
        content: 'If you’re looking for a trustworthy handyman in San Clemente, you’re in the right place. ...',
        date: '11-08-2024',
    },
    {
        id: 32,
        slug: 'handyman-mission-viejo',
        title: 'Your Trusted Handyman in Mission Viejo: Expert Services for Every Home and Business',
        content: 'When it comes to maintaining and improving your home or office in Mission Viejo, finding ...',
        date: '09-03-2024',
    },
    {
        id: 33,
        slug: 'handyman-lake-forest',
        title: 'Your Go-To Handyman Services in Lake Forest, CA',
        content: 'When it comes to home repairs, maintenance, or small renovation projects, finding a reliable handyman ...',
        date: '02-01-2024',
    },
    {
        id: 34,
        slug: 'handyman-newport-beach',
        title: 'Reliable Handyman Services in Newport Beach: Your Go-To Solution for Home Repairs',
        content: 'If you’re a homeowner or renter in Newport Beach, you know that maintaining your property is crucial to ensuring ...',
        date: '04-11-2024',
    }

];

export default articles;