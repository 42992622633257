import React from "react";
import "../../index.css";

function CookiePolicy() {
  return (
    <section className="cookie-policy">
      <div className="support_container">
        <h1>Cookie Policy</h1>
        <p>
          At HubPro, we are committed to ensuring that your interaction with our
          website is smooth, personalized, and efficient. Our use of cookies is
          designed with this commitment in mind. This Cookie Policy provides
          detailed information on our use of cookies, including the types we use
          and the purposes they serve.
        </p>

        <h2>What Are Cookies?</h2>
        <p>
          Cookies are small text files stored on your device (computer, tablet,
          smartphone, etc.) when you visit a website. These files are used by
          the website to recognize your device on subsequent visits, enhancing
          your user experience by remembering your preferences and usage
          patterns. For more information about cookies, we recommend visiting{" "}
          <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>{" "}
          and{" "}
          <a href="https://www.youronlinechoices.eu">
            www.youronlinechoices.eu
          </a>
          .
        </p>

        <h2>How We Use Cookies</h2>
        <p>
          HubPro utilizes cookies for various purposes to improve your
          experience on our site. The cookies we use can be divided into the
          following categories:
        </p>

        <ol>
          <li>
            <strong>Essential Cookies:</strong> These cookies are strictly
            necessary for our website to function and cannot be switched off
            without affecting the use of the site. They are set in response to
            actions made by you, such as setting your privacy preferences or
            logging in.
          </li>
          <li>
            <strong>Analytical Cookies:</strong> We use analytical cookies to
            understand how our visitors interact with the website. This includes
            tracking pages visited and actions taken. The tools we use for this
            analysis include Segment, Google Analytics, Fullstory, and Split.
            These cookies help us improve our site's functionality and your
            overall experience.
          </li>
          <li>
            <strong>Functional Cookies:</strong> Functional cookies enable our
            website to provide enhanced functionality and personalization, based
            on your interactions.
          </li>
          <li>
            <strong>Advertising Cookies:</strong> HubPro utilizes advertising
            cookies to deliver ads that are most relevant to you and your
            interests. These cookies may track your online activity and collect
            information to deliver tailored advertising across various
            platforms, including Google Ads, Bing Ads, Impact Radius, Facebook,
            and Instagram.
          </li>
        </ol>

        <h2>E.U. Users' Right to Refuse Cookies</h2>
        <p>
          If you are in the European Union, you have the right to refuse the
          placement of cookies on your device. You can exercise this right by
          adjusting your browser settings to decline cookies. Please note,
          however, that doing so may impact your ability to use certain features
          of our website effectively.
        </p>

        <h2>Managing Cookies</h2>
        <p>
          Most web browsers allow some control of most cookies through the
          browser settings. To find out more about how to manage and delete
          cookies, visit{" "}
          <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.
        </p>

        <h2>Have Questions?</h2>
        <p>
          If you have any questions or concerns regarding our Cookie Policy,
          please do not hesitate to contact us at{" "}
          <a href="mailto:info@hubpro.us">info@hubpro.us</a>
        </p>

        <h2>Policy Updates</h2>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review our Cookie Policy periodically to
          stay informed about how we are using cookies.
        </p>
      </div>
    </section>
  );
}

export default CookiePolicy;
