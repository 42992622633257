import React from "react";
import "../../index.css";

function Contact() {
  const city = "Orange County";

  return (
    <section className="mobile-container-home" id="furniture_assembly">
      <div class="features__container container">
        <div class="features__list-container">
          <ul>
            <li>
              <p>
                <h2 class="features__h1">Contact:</h2>
              </p>
              <p class="features__text">
                <strong>HubPro.US - Orange County #1 Handyman Service</strong>
              </p>
              <p class="features__text">
                <strong>Phone:</strong>{" "}
                <a href="tel:+19494380343">(949) 438-0343</a>
              </p>
              <p class="features__text">
                <strong>Email:</strong>{" "}
                <a href="mailto:info@hubpro.us">info@hubpro.us</a>
              </p>
              <p class="features__text">
                <strong>Address:</strong> 9920 Irvine Center Dr, Irvine, CA
                92618
              </p>
              <p class="features__text">
                <strong>Hours:</strong> Monday-Sunday, 8:00 AM - 8:00 PM
              </p>
              <p class="features__text">
                <strong>Service Area:</strong> Serving Only Orange County
              </p>
            </li>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.0484214172684!2d-117.73326892377077!3d33.62998647331889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce9d75ddfd18f%3A0x83e5ad5f3286088b!2sHubPro%20-%20Furniture%20Assembly%20%26%20TV%20Mounting%20Service!5e0!3m2!1sen!2sus!4v1713940143466!5m2!1sen!2sus"
                width="580"
                height="325"
                style={{ border: 0, marginTop: "40px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </ul>
        </div>
      </div>
      <div>
        <a
          class="get-quote__link action-button"
          id="bottomSmsButtonContactPage"
          href="sms:+19494380343"
        >
          Text For Free Quote
        </a>
      </div>
    </section>
  );
}

export default Contact;
