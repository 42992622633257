import React from "react";
import Check from "../../assets/image/check.webp";

function PrivacyPolicy() {
  return (
    <section className="privacy-policy">
      <div className="support_container">
        <h1>Privacy Policy</h1>
        <strong>Our Commitment</strong>
        <br />
        HubPro values your privacy and is dedicated to protecting your personal
        information. This privacy policy outlines how we collect, use, share,
        and safeguard your information. If you have any inquiries about this
        policy or our privacy practices, please contact us using the information
        provided in the "Contact Us" section at the end of this document.
        <br />
        <br />
        <strong>Agreement to Terms</strong>
        <br />
        Your use of our services is voluntary, and you are not obligated to
        provide any personal information unless required for specific features.
        If you do not agree with the terms outlined in this Privacy Policy, we
        advise discontinuing the use of our services.
        <br />
        <br />
        <strong>Effective Date and Changes</strong>
        <br />
        This Privacy Policy is effective as of the date stated above and will
        remain in effect until updated. We reserve the right to modify this
        policy at our discretion, and it is recommended to periodically review
        this page for any changes. Major updates will be communicated through
        our website or via email.
        <br />
        <br />
        <strong>Information Collection</strong>
        <br />
        We may collect and process various types of personal information,
        including but not limited to:
        <br />
        - Personal Identifiers
        <br />
        - Internet or electronic network activity information
        <br />
        - Professional or employment-related details
        <br />
        - Geolocation data
        <br />
        - Educational background and skills
        <br />
        - Characteristics of protected classifications
        <br />
        - Visual, audio, electronic, and similar data
        <br />
        - Commercial information and inferences drawn from collected data
        <br />
        This information is obtained directly from you or indirectly through
        third-party partners.
        <br />
        <br />
        <strong>4.1 Cookies</strong>
        <br />
        Cookies are utilized for identification and to enhance your browsing
        experience. You have the option to manage or disable cookies, although
        this may affect certain features of our services.
        <br />
        <br />
        <strong>4.2 Device Information</strong>
        <br />
        We automatically collect information about the devices used to access
        our services, including IP addresses, geolocation data, and browsing
        behavior, to improve our offerings and user experience.
        <br />
        <br />
        <strong>4.3 Analytics</strong>
        <br />
        General information about user activity may be collected for analytical
        purposes, aiding in service enhancement and marketing strategies.
        <br />
        <br />
        <strong>4.4 Children's Privacy</strong>
        <br />
        We are committed to protecting the online privacy of children under 16
        and do not knowingly collect their personal information.
        <br />
        <br />
        <strong>Use of Personal Information</strong>
        <br />
        We use your personal information for various purposes, including
        managing your account, providing requested services, marketing
        communications, website analysis, and ensuring security and compliance
        with legal obligations.
        <br />
        <br />
        <strong>Sharing Your Information</strong>
        <br />
        Personal information may be shared with third parties such as advisers,
        service providers, business partners, and governmental bodies for
        operational and legal purposes.
        <br />
        <br />
        <strong>Privacy Choices</strong>
        <br />
        You have the option to unsubscribe from certain communications, and we
        respect your preferences regarding the use of your personal information.
        <br />
        <br />
        <strong>Storage and Security</strong>
        <br />
        We maintain measures to safeguard personal information from unauthorized
        access, alteration, or destruction, although absolute security cannot be
        guaranteed.
        <br />
        <br />
        <strong>California Do-Not-Track Disclosures</strong>
        <br />
        While we respect privacy preferences, we do not alter our data
        collection practices in response to "do-not-track" signals.
        <br />
        <br />
        <strong>Additional Disclosures for California Consumers</strong>
        <br />
        California consumers have rights regarding the collection, use, and
        disclosure of their personal information, including the right to access
        and delete such information. We provide necessary disclosures in
        compliance with California law.
        <br />
        <br />
        <strong>Links to Third Parties</strong>
        <br />
        Our website may contain links to third-party websites with independent
        privacy practices, for which we are not responsible.
        <br />
        <br />
        <strong>Contact Us</strong>
        <br />
        For any questions or concerns regarding this Privacy Policy or our
        privacy practices, please contact us at privacy@hubpro.com.
        <address>
          Orange County Furniture Assembly & TV Mounting Service
          <br />
          9920 Irvine Center Dr,
          <br />
          Irvine, CA 92618
          <br />
          Email: info@hubpro.us
          <br />
          Phone: (949) 438-0343
          <br />
        </address>
        <p>This Privacy Policy was last updated on 04/13/2024.</p>
        <p>
          By using our website, you consent to the terms of this Privacy Policy.
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
