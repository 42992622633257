import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <nav className='menu'>
      <ul className='menu__list'>
        <li className='menu__item'>
          <Link className="menu__link" to="/">Home</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/handyman_services">Handyman</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/painters-near-me">Painting</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/furniture_assembly">Furniture Assembly</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/tv_mounting">TV Mounting</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/about">About</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/blog">Blog</Link>
        </li>
        <li className='menu__item'>
          <Link className="menu__link" to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
