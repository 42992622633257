import React from "react";

const HandymanServiceCard = ({ service, city, openModal }) => {
  return (
    <div className="handyman-service">
      <img
        onClick={() => openModal(service.name)}
        src={service.image}
        alt={`Handyman ${service.name} in ${city}`}
        className="handyman-service-image"
      />
      <div className="handyman-service-content">
        <h3 className="handyman-service-title">
          {service.name} in {city}
        </h3>
        <p className="handyman-service-description">{service.description}</p>
        <button
          onClick={() => openModal(service.name)}
          className="handyman-service-button"
        >
          Get a Free Quote
        </button>
      </div>
    </div>
  );
};

export default HandymanServiceCard;
