import Repairs from "../assets/servicesImg/home-repairs-image.webp";
import Pictures from "../assets/servicesImg/hang-pictures-image.webp";
import Shelf from "../assets/servicesImg/shelf-mounting-image.webp";
import Curtains from "../assets/servicesImg/curtains-blinds-image.webp";
import Ceiling from "../assets/servicesImg/ceiling-fan-image.webp";
import Maintenance from "../assets/servicesImg/home-maintenance-image.webp";
import appliance from "../assets/servicesImg/appliance-image.webp";
import Electrical from "../assets/servicesImg/electrical-image.webp";
import Plumbing from "../assets/servicesImg/plumbing-image.webp";
import Fence from "../assets/servicesImg/fence-installation-image.webp";
import Smart from "../assets/servicesImg/smart-home-image.webp";
import Theater from "../assets/servicesImg/home-theater-image.webp";
import RomanPhoto from "../assets/image/roman.jpg";
import furnitureImage from "../assets/servicesImg/furniture_assembly_2742.webp";
import tvImage from "../assets/servicesImg/tv_mounting_service_6.webp";
import Delivery from "../assets/servicesImg/delivery.webp";

export const services = [
  {
    name: "Home Repairs",
    description:
      "General repair services to address various issues around your home.",
    image: Repairs,
  },
  {
    name: "Hang Pictures",
    description:
      "Professional hanging of pictures and artwork to enhance your home decor.",
    image: Pictures,
  },
  {
    name: "Shelf Mounting",
    description: "Secure mounting of shelves to optimize storage space.",
    image: Shelf,
  },
  {
    name: "Hanging Curtains & Installing Blinds",
    description:
      "Expert assistance in hanging curtains and installing blinds for privacy and aesthetics.",
    image: Curtains,
  },
  {
    name: "Ceiling Fan Installation",
    description:
      "Installation of ceiling fans to improve air circulation in your home.",
    image: Ceiling,
  },
  {
    name: "Home Maintenance ",
    description:
      "Regular maintenance services to keep your home in top condition.",
    image: Maintenance,
  },
  {
    name: "Plumbing Services",
    description:
      "Professional plumbing services for residential and commercial spaces.",
    image: Plumbing,
  },
  {
    name: "Appliance Installation",
    description:
      "Professional installation and repair services for household appliances.",
    image: appliance,
  },
  {
    name: "Electrical Help",
    description: "Expert electrical services for your home or office.",
    image: Electrical,
  },
  {
    name: "Fence Installation & Repair Services",
    description:
      "Installation and repair of fences to enhance security and privacy.",
    image: Fence,
  },
  {
    name: "Garage Door Repair",
    description:
      "Expert repair services for garage doors to ensure smooth operation and security of your garage.",
    image: Repairs,
  },
  {
    name: "Smart Home Installation",
    description:
      "Installation and setup of smart home devices for convenience and efficiency.",
    image: Smart,
  },
  // { name: "Cabinet Installation", description: "Installation of cabinets for improved storage solutions.", image: Cabinet },
  {
    name: "Painting",
    description:
      "Comprehensive painting services to refresh and enhance the look of your home, covering walls, ceilings, trim, and more.",
    image: RomanPhoto,
  },
  {
    name: "Home Theater Installing",
    description:
      "Installation and setup of home theater systems for an immersive entertainment experience.",
    image: Theater,
  },
  {
    name: "TV Mounting",
    description:
      "I'm a skilled TV mounting technician. I install all types of TVs and hide the wires for a clean look. Enjoy the best viewing experience with my reliable and affordable service. Book now!",
    image: tvImage,
  },
  {
    name: "Furniture Assembly",
    description:
      "With 15 years of experience, I assemble all types of furniture quickly and efficiently. Enjoy hassle-free, perfectly assembled furniture. Book now for reliable and affordable service!",
    image: furnitureImage,
  },
  {
    name: "Local Express Delivery & Small Moves",
    description:
      "I specialize in organizing swift local delivery from prominent furniture and electronics stores such as IKEA, Living Spaces, Costco, Jerome's Furniture, and others. My services include efficient furniture removal, small-scale local moves, furniture donation drop-offs, furniture disposal, and comprehensive garage clean-outs with debris removal.",
    image: Delivery,
  },
];
