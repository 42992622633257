import React, {useState} from "react";
import GetQuote from "../quote/GetQuotePainting";
import Reviews from '../reviews/ReviewsPainters';
import GetQuoteForm from '../quote/GetQuotePainters';
import Modal from 'react-modal';

import ImageSliderPainter from "../sliders/painting/ImageSliderPainting";
import ImageSliderPainter2 from "../sliders/painting/ImageSliderPainting_2";
import ImageSliderPainter3 from "../sliders/painting/ImageSliderPainting_3";
import ImageSliderPainter4 from "../sliders/painting/ImageSliderPainting_4";
import ImageSliderPainter5 from "../sliders/painting/ImageSliderPainting_5";

function Painters() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const openModal = (serviceName) => {
        setSelectedService(serviceName);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedService(null);
        setModalIsOpen(false);
    };

    const handleSubmit = (formData) => {
        console.log('Form data submitted from GetQuoteForm:', formData);
        closeModal();
    };


    const city = "Orange County"

    return (
        <section className="features" id="tv_mounting_service">
            <div className="container">
                <div className="container_tv">

                    <div className="mobile-container">

                        <br></br><br></br>
                        <h2 className="about__h1">Professional Painters Service in <span
                            style={{color: 'orange', fontWeight: 'bold'}}>{city}</span> - Trusted & Reliable</h2>
                        <br></br>

                        <div className="handyman_header_desc">

                            Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong> – your go-to for
                            expert house painting! Whether you need <strong>"painters near me," "exterior painters," or
                            "interior painters,"</strong> our family-owned business delivers flawless results. From
                            cabinets to full house painting, we provide reliable, professional service every time. Let
                            us transform your home with the perfect finish!

                        </div>
                        <br></br>

                    </div>


                    <div className="mobile-container">
                        <div>
                            <ul class="tv_mounting_skills_list">
                                <p class="tv_mounting_skills">Premium Paint Finishes</p>
                                <p class="tv_mounting_skills">Precision Home Painting</p>
                                <p class="tv_mounting_skills">Stunning Color Transformations</p>
                                <p class="tv_mounting_skills">Expert Interior & Exterior</p>
                            </ul>

                        </div>
                    </div>


                    <div className="mobile-container">
                        <h2 className="about__h1">
                            Best Painting Service in <span style={{color: 'orange', fontWeight: 'bold'}}>{city}</span>
                        </h2>
                        <br/>
                        <ImageSliderPainter/>
                        <br></br>
                        <br></br>
                    </div>

                    <div>
                        <a
                            className="get-quote__link action-button"
                            id="bottomSmsButtonFurniturePage"
                            onClick={() => openModal()}
                        >
                            Get a Free Estimate
                        </a>
                        <br></br>
                    </div>


                    <div className="mobile-container">
                        <Reviews/>
                        <br></br>
                        <br></br>
                    </div>


                    <div className="mobile-container">
                        <h2 className="about__h1">
                            Painters Near Me
                        </h2>
                        <br/>
                        <ImageSliderPainter2/>
                        <br></br>
                        <br></br>
                    </div>

                    <div>
                        <a
                            className="get-quote__link action-button"
                            id="bottomSmsButtonFurniturePage"
                            onClick={() => openModal()}
                        >
                            Get a Free Estimate
                        </a>
                        <br></br>
                    </div>


                    <div className="mobile-container">
                        <h2 className="about__h1">
                            House Painters Near Me / Interior Painters Near Me / Exterior Painters Near Me
                        </h2>
                        <br/>
                        <ImageSliderPainter3/>
                        <br></br>
                        <br></br>
                    </div>


                    <br/>
                    <ImageSliderPainter4/>
                    <br></br>
                    <br></br>


                    <br/>
                    <ImageSliderPainter5/>
                    <br></br>
                    <br></br>


                    <div>
                        <a
                            className="get-quote__link action-button"
                            id="bottomSmsButtonFurniturePage"
                            onClick={() => openModal()}
                        >
                            Get a Free Estimate
                        </a>
                        <br></br>
                    </div>


                    <div className="container_tv_qoute_form">
                        <br></br>
                        <GetQuote/>
                        <br></br>
                        <br></br>
                    </div>


                    <div className="mobile-container">
                        <div className="text">
                            <h3>Top-Tier Painting Services in {city}</h3>
                            <p>
                                Transform your home with our expert painting services! Whether you’re looking for
                                interior perfection or exterior durability, our skilled painters near you
                                in {city} deliver flawless results every time. From house painting to cabinet
                                refinishing, we ensure your home looks stunning.
                            </p>

                            <h3>Precision and Attention to Detail</h3>
                            <p>
                                We take pride in our meticulous approach to every project. Our interior painters
                                specialize in clean lines, smooth finishes, and a flawless transformation of your living
                                spaces. For exterior projects, we use premium materials designed to withstand weather
                                and time.
                            </p>

                            <h3>Personalized Painting Solutions</h3>
                            <p>
                                Every home is unique, and so are your needs. That’s why we offer tailored painting
                                services that suit your style, budget, and preferences. From cabinet painting to
                                large-scale home projects, we ensure the perfect look for your space.
                            </p>

                            <h3>Experience You Can Trust</h3>
                            <p>
                                As trusted house painters near you, we bring years of experience and a proven track
                                record of customer satisfaction. Our team uses best practices and the latest techniques
                                to achieve professional, long-lasting results.
                            </p>

                            <h3>Enhance Your Home's Value</h3>
                            <p>
                                A fresh coat of paint doesn’t just make your home look great—it increases its value.
                                Whether it’s a modern update or restoring its original charm, our painting services help
                                your property stand out.
                            </p>

                            <h3>Eco-Friendly Practices</h3>
                            <p>
                                We care about your health and the environment. That’s why we use low-VOC paints and
                                sustainable materials, ensuring a safe and eco-friendly experience for you and your
                                family.
                            </p>

                            <h3>Support Your Local {city} Business</h3>
                            <p>
                                As a family-owned business in {city}, we’re proud to serve our local community. Choosing
                                us means supporting dedicated professionals who care about the quality of their work and
                                the satisfaction of their neighbors.
                            </p>
                        </div>

                    </div>


                    <br></br>
                    <div className="mobile-only">
                        <a
                            className="get-quote__link action-button"
                            id="bottomSmsButtonTvPage"
                            href="sms:949-438-0343"
                        >
                            Get a Free Estimate!
                        </a>
                    </div>
                </div>
            </div>


            <div className="get-quote">
                <div className="get-quote__container container">
                    <div className="get-quote__p">
                        Transform Your Home with Expert Painting Services {city}!
                    </div>
                    <a
                        className="get-quote__link action-button"
                        id="bottomSmsButtonTvPage"
                        href="sms:+19494380343"
                    >
                        Text Us 24/7
                    </a>
                </div>
            </div>


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Get a Free Quote Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        top: '50%',
                        background: 'linear-gradient(to bottom, #0090ea, #0064cc)', // Исправлено здесь
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        maxWidth: '600px', // Можно настроить ширину модального окна
                        maxHeight: '1600px', // Можно настроить максимальную высоту модального окна
                        overflowY: 'auto', // Разрешить прокрутку содержимого, если оно не помещается
                        borderRadius: '10px', // Закругление углов
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Тень
                        border: 'none' // Убрать окантовку
                    }
                }}
            >
                <h5 className="banner-quote__h1">Get a Free Estimate Today!</h5>
                <GetQuoteForm handleSubmit={handleSubmit}/>
            </Modal>
        </section>
    );
}

export default Painters;