import React from 'react';
import Slider from 'react-slick';

import Google_Reviews from '../../assets/image/google_g_icon.webp';
import Yelp_Reviews from '../../assets/image/yelp_logo.webp';
import DefaultYelpAvatar from '../../assets/image/default.png';
import YelpFavicon from '../../assets/image/yelp_favicon.png';
import GoogleFavicon from '../../assets/image/favicon_google.png';

import Sophia from '../../assets/image/rewiews_painting/painting_rewiews_1.png';
import Brian from '../../assets/image/rewiews_painting/painting_rewiews_2.png';
import Emily from '../../assets/image/rewiews_painting/painting_rewiews_3.png';
import Tom from '../../assets/image/rewiews_painting/painting_rewiews_4.png';
import Ava from '../../assets/image/rewiews_painting/painting_rewiews_5.png';
import Lily from '../../assets/image/rewiews_painting/painting_rewiews_6.png';
import Olivia from '../../assets/image/rewiews_painting/painting_rewiews_7.png';
import David from '../../assets/image/rewiews_painting/painting_rewiews_8.png';
import Jessica from '../../assets/image/rewiews_painting/painting_rewiews_9.png';
import Chris from '../../assets/image/rewiews_painting/painting_rewiews_10.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom arrow components
const PrevArrow = ({ className, style, onClick }) => (
  <div className={`${className} slick-prev-tv`} style={style} onClick={onClick} />
);

const NextArrow = ({ className, style, onClick }) => (
  <div className={`${className} slick-next-tv`} style={style} onClick={onClick} />
);

// Reviews data
const reviews = [
  {
    platformIcon: Google_Reviews,
    avatar: Sophia,
    name: 'Sophia T.',
    date: 'January 22, 2024',
    stars: '★★★★★',
    review: `HubPro did an amazing job painting our kitchen! Roman was so professional and the results are stunning. The paint job is flawless, and he took the time to clean up thoroughly. Highly recommend!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Brian,
    name: 'Brian Edwards',
    date: 'February 18, 2024',
    stars: '★★★★★',
    review: `Roman transformed our apartment with a fresh coat of paint. He worked efficiently, and the color scheme looks fantastic. The space feels completely new. Couldn’t be happier with the service!`,
    link: 'https://www.yelp.com/biz/hubpro-furniture-assembly-irvine',
  },
  {
    platformIcon: Google_Reviews,
    avatar: Emily,
    name: 'Daisy Martinez',
    date: 'March 12, 2024',
    stars: '★★★★★',
    review: `HubPro did an incredible job painting our front door. Roman gave us advice on the best color, and it turned out beautifully. The whole experience was easy and professional. Definitely would use them again!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Google_Reviews,
    avatar: David,
    name: 'David Harris',
    date: 'April 8, 2024',
    stars: '★★★★★',
    review: `Roman painted the interior of our house, and we couldn’t be more impressed. The work was done quickly and with exceptional attention to detail. The entire team was friendly, and the results are flawless!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Jessica,
    name: 'Jessy R.',
    date: 'May 4, 2024',
    stars: '★★★★★',
    review: `HubPro painted our apartment, and the transformation was unbelievable. Roman and his team were professional and efficient. I can’t believe how fresh and bright the place looks now. Highly recommend!`,
    link: 'https://www.yelp.com/biz/hubpro-furniture-assembly-irvine',
  },
  {
    platformIcon: Google_Reviews,
    avatar: Tom,
    name: 'Kevin Johnson',
    date: 'June 14, 2024',
    stars: '★★★★★',
    review: `Roman did an outstanding job painting our living room and dining area. The quality of work and speed were fantastic. He was respectful of our home and left everything spotless. I’m beyond satisfied!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Google_Reviews,
    avatar: Ava,
    name: 'Ava Lee',
    date: 'July 25, 2024',
    stars: '★★★★★',
    review: `HubPro repainted our apartment’s exterior, and it looks fantastic. The color choices are perfect, and the quality is unmatched. Roman was prompt, professional, and cleaned up after finishing. Highly recommend!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Lily,
    name: 'Diego Martinez',
    date: 'August 9, 2024',
    stars: '★★★★★',
    review: `Roman painted our master bedroom, and it’s beautiful! He was so efficient and paid attention to every little detail. The finish is perfect, and he even gave us helpful tips for maintenance. We will definitely call him again!`,
    link: 'https://www.yelp.com/biz/hubpro-furniture-assembly-irvine',
  },
  {
    platformIcon: Google_Reviews,
    avatar: Chris,
    name: 'Chris Brown',
    date: 'September 17, 2024',
    stars: '★★★★★',
    review: `HubPro painted our whole house and it looks amazing. Roman was very helpful, guiding us through color options and giving honest advice. The job was done efficiently and professionally, with no mess left behind. Amazing service!`,
    link: 'https://g.page/r/CYsIhjJfreWDEB0/',
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Olivia,
    name: 'Oliver W.',
    date: 'October 22, 2024',
    stars: '★★★★★',
    review: `I couldn’t be happier with HubPro’s house painting service! Roman painted our living room, hallway, and kitchen, and the results are gorgeous. Quick, professional, and high-quality work. Will definitely recommend to friends!`,
    link: 'https://www.yelp.com/biz/hubpro-furniture-assembly-irvine',
  },
];
  

function ReviewsPainters() {
  const city = 'Orange County';

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="ReviewsTV">
      <div>
        <h2 className="reviews-tv__header">
          Real <span style={{ color: 'orange', fontWeight: 'bold' }}>5-Star </span>
          <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <img
              src={YelpFavicon}
              alt="Yelp favicon"
              style={{ width: '24px', height: '24px', marginLeft: '10px', marginRight: '3px' }}
            />
          </span>
          Yelp and
          <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <img
              src={GoogleFavicon}
              alt="Google favicon"
              style={{ width: '24px', height: '24px', marginLeft: '10px', marginRight: '3px' }}
            />
          </span>
          Google Reviews About Us from Your {city} Neighbors
        </h2>
        <Slider {...settings} className="reviews-tv__slider">
          {reviews.map((review, index) => (
            <div className="reviews-tv__review" key={index}>
              <img src={review.platformIcon} alt="Platform Icon" className="reviews-tv__icon" />
              <img src={review.avatar} alt={`${review.name} Avatar`} className="reviews-tv__avatar_2" />
              <div className="reviews-tv__author-wrapper">
                <a
                  className="reviews-tv__author"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={review.link}
                >
                  {review.name}
                </a>
                <div className="reviews-tv__date">{review.date}</div>
                <div className="reviews-tv__stars">{review.stars}</div>
              </div>
              <div className="reviews-tv__content">
                <p>{review.review}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ReviewsPainters;