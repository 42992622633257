export const handymanTopDescription = {
  orangeCounty: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Orange County</span>. Orange County, known for its beautiful coastline, vibrant cities, and family-friendly communities, offers a unique blend of suburban charm and urban convenience. As your local handyman, we offer specialized home repairs and maintenance services tailored to the needs of Orange County residents.",
    bottomDescription:
      "Welcome to <strong>HubPro.US</strong>, your trusted <strong>handyman service in Orange County, CA</strong>! Located in the heart of Southern California, Orange County is renowned for its stunning beaches, world-class attractions, and diverse neighborhoods. Whether you're in need of home maintenance, repairs, or appliance installations, we provide reliable, professional handyman services designed to keep your home in top shape. Our family-owned business takes pride in offering fast, same-day service, ensuring your home stays in perfect condition. Trust <strong>HubPro.US</strong> for all your home improvement needs in Orange County, with expert craftsmanship and attention to detail. Get your free quote today!",
  },
  alisoViejo: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Aliso Viejo</span>. Aliso Viejo is known for its beautiful suburban atmosphere and tight-knit community. As your local handyman, we offer specialized home repairs and maintenance services tailored to the needs of Aliso Viejo residents.",
    bottomDescription:
      "Welcome to <strong>HubPro.US</strong>, your trusted <strong>handyman service in Aliso Viejo, CA</strong>! Nestled in the heart of beautiful Orange County, Aliso Viejo is known for its scenic parks, top-rated schools, and family-friendly neighborhoods. Whether you need home maintenance, repairs, appliance installations, or anything in between, we provide reliable, professional services tailored to your needs. As a local family-owned business, we take pride in offering fast, efficient handyman solutions, ensuring your home stays in perfect condition. Trust <strong>HubPro.US</strong> to handle your home improvement needs with expert care and attention to detail in Aliso Viejo. Get your free quote today!",
  },
  anaheim: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Anaheim</span>. In Anaheim, home to the famous Disneyland Resort, we understand that your home is your castle. We offer expert handyman services designed to keep your home in top shape with high-quality repairs and installations.",
    bottomDescription:
      "Looking for a reliable <strong>handyman service in Anaheim</strong>? <strong>HubPro.US</strong> offers top-notch home repairs, maintenance, and installations to keep your home in perfect shape. As a family-owned business serving the Anaheim community, we understand the unique needs of our residents, from the stunning Disneyland area to the vibrant GardenWalk. Whether you need help with plumbing, electrical work, appliance installation, or home repairs, we’ve got you covered. Our skilled professionals provide fast, friendly, and affordable services tailored to your needs. Trust <strong>HubPro.US</strong> for all your handyman solutions in Anaheim – quality service you can rely on!",
  },
  brea: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Brea</span>. Brea, a city with a vibrant downtown, deserves handyman services that match its energy. From home repairs to renovations, we provide reliable and affordable solutions for all your needs.",
    bottomDescription:
      "Looking for reliable and skilled <strong>handyman services in Brea</strong>? <strong>HubPro.US</strong> is your trusted local handyman, offering everything from home repairs and appliance installation to plumbing and electrical work. With our deep ties to the Brea community, we understand the unique needs of homeowners in this beautiful city known for its vibrant downtown, scenic parks, and family-friendly neighborhoods. Whether you need help with minor repairs or large projects, <strong>HubPro.US</strong> guarantees timely, professional service. Let us take care of your home improvement needs—trusted by Brea residents for quality, reliability, and competitive pricing. Call us today for a free quote!",
  },
  buenaPark: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Buena Park</span>. Buena Park, known for its fun-filled attractions, also deserves a reliable handyman. Whether it's repairs, maintenance, or installations, we provide services that meet the needs of busy families and homeowners.",
    bottomDescription:
      "Welcome to <strong>HubPro.US</strong>, your trusted family <strong>handyman service in Buena Park, California</strong>! Known for its vibrant attractions like Knott’s Berry Farm and rich history, Buena Park is more than just a fun destination — it’s a community that values home comfort. Whether you're in need of appliance installation, plumbing services, or general repairs, <strong>HubPro.US</strong> offers expert, same-day handyman services to keep your home in top shape. Our skilled team ensures reliable, affordable solutions with a personal touch, dedicated to making your home improvement projects hassle-free. Call us today for a free quote and experience the best handyman service in Buena Park!",
  },
  costaMesa: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Costa Mesa</span>. Costa Mesa's thriving arts scene and beautiful homes need reliable handyman services. We specialize in keeping your home beautiful and functional, whether it's minor repairs or regular maintenance.",
    bottomDescription:
      "Looking for reliable <strong>handyman services in Costa Mesa, CA</strong>? At <strong>HubPro.US</strong>, we offer top-notch home repairs, maintenance, and installations tailored to meet the needs of Costa Mesa residents. Whether you're in the heart of the city or near the scenic Back Bay, our skilled team is here to handle everything from plumbing and electrical work to furniture assembly and smart home installations. As a locally trusted, family-owned business, we pride ourselves on delivering quality craftsmanship with a personal touch. With Costa Mesa’s unique blend of coastal charm and modern living, we ensure your home stays in top shape all year round!",
  },
  cotoDeCaza: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Coto De Caza</span>. In Coto De Caza, a place known for its luxury homes and exclusive community, we provide expert handyman services to maintain the elegance and functionality of your property.",
    bottomDescription:
      "Welcome to <strong>HubPro.US</strong>, your trusted <strong>handyman service in Coto de Caza, CA</strong> – a beautiful, gated community known for its lush landscapes and family-friendly atmosphere. Whether you need home repairs, maintenance, or custom installations, our skilled craftsmen are here to help. From appliance installations to fence repairs, we offer fast, reliable service for every home project. As a family-owned business, we understand the unique needs of Coto de Caza residents and are committed to delivering quality work with a personal touch. Choose <strong>HubPro.US</strong> for your handyman needs – your go-to service for everything around the house!",
  },
  cowanHeights: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Cowan Heights</span>. Cowan Heights offers stunning views and peaceful living. As your trusted handyman service, we specialize in home repairs and installations that keep your home safe and well-maintained.",
    bottomDescription:
      "Looking for reliable <strong>handyman services in Cowan Heights</strong>, CA? HubPro.US is your trusted local expert for all home repairs, maintenance, and installations. Nestled in the heart of Orange County, Cowan Heights offers a unique blend of scenic hillside views and suburban charm — making it a prime location for homeowners who need quality, timely handyman services. Whether it's fixing a leaky faucet, installing a ceiling fan, or assembling furniture, our experienced professionals are here to ensure your home stays in top shape. Call HubPro.US today for a free quote and experience the best handyman service in Cowan Heights!",
  },
  cypress: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Cypress</span>. Cypress, a city that values family and community, can count on us for professional handyman services. Whether it's home repairs or improvements, we provide top-quality solutions for Cypress residents.",
    bottomDescription:
      "Looking for a reliable <strong>handyman in Cypress, CA? HubPro.US is your go-to family-owned service provider for all your home improvement needs in this vibrant community! Whether you're in need of quick repairs, appliance installations, or expert home maintenance, our skilled craftsmen are ready to assist. Known for its charming suburban feel and proximity to the beautiful Cypress College, Cypress residents trust us to deliver fast, efficient, and affordable handyman services. No job is too big or small — we offer same-day service, guaranteed on-time arrival, and priority support. Let us help you keep your home in top shape today!",
  },
  huntingtonBeach: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Huntington Beach</span>. Huntington Beach, known for its sunny weather and surf culture, requires handyman services that are as reliable as the beach itself. We offer home repairs and maintenance that fit the coastal lifestyle.",
    bottomDescription:
      "If you're in Huntington Beach, CA, and need a reliable <strong>handyman service</strong>, HubPro.US is here to help. Known for its beautiful coastline and laid-back surf culture, Huntington Beach deserves the best home maintenance and repair services. Our expert team offers everything from appliance installation to plumbing and electrical repairs, ensuring your home stays in top condition. Whether you need to mount a TV or install a ceiling fan, HubPro.US provides fast, friendly, and professional handyman services with a local touch. Trust us to handle all your home improvement needs with the reliability you expect from a hometown business.",
  },
  irvine: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Irvine</span>. Irvine is a hub of innovation and beautiful neighborhoods. Our handyman services are designed to support the modern needs of Irvine residents with quality repairs and efficient solutions for all your home maintenance needs.",
    bottomDescription:
      "Looking for a reliable <strong>handyman in Irvine</strong>, CA? HubPro.US offers top-notch home repair and maintenance services with a personal touch. Whether you're tackling home repairs, appliance installations, or simply need help with minor improvements, our skilled professionals are here to make your home shine. As a family-owned business, we understand the importance of a well-maintained home in the heart of Irvine, known for its beautiful parks and vibrant community. We guarantee fast, quality service with a smile. Call HubPro.US today and experience the best handyman services in Irvine!",
  },
  laderaRanch: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Ladera Ranch</span>. Ladera Ranch, a family-friendly community with a focus on outdoor living, needs a handyman service that understands its unique needs. We provide home repairs, maintenance, and improvement solutions tailored for Ladera Ranch homes.",
    bottomDescription:
      "HubPro.US offers reliable <strong>handyman services in Ladera Ranch</strong>, CA, where we pride ourselves on delivering top-notch home repairs, maintenance, and installations tailored to the unique needs of this vibrant community. Whether you're in need of appliance installation, plumbing, electrical help, or home renovations, our skilled team is here to provide quick and efficient solutions. As a family-owned business, we understand the importance of maintaining your home in this beautiful, family-friendly area, and we're committed to making sure your space remains functional and well-maintained. Trust HubPro.US for all your handyman needs in Ladera Ranch – where quality meets convenience.",
  },
  lagunaBeach: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Laguna Beach</span>. Laguna Beach is renowned for its artistic vibe and coastal beauty. Our handyman services are designed to preserve the charm of your home while keeping it functional and beautiful with expert repairs and maintenance.",
    bottomDescription:
      "Looking for a reliable <strong>handyman in Laguna Beach</strong>, CA? HubPro.US is your go-to local expert, offering top-notch handyman services tailored to the unique needs of this coastal paradise. Whether you need home repairs, maintenance, or installation services, our skilled craftsmen are here to help. We specialize in everything from plumbing and electrical work to furniture assembly and home theater setup. Proudly serving the residents of Laguna Beach, known for its stunning coastal views and vibrant arts scene, we understand the importance of quality service. Choose HubPro.US for your next handyman project and experience the difference!",
  },
  lagunaHills: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Laguna Hills</span>. Laguna Hills, with its peaceful surroundings and family-oriented community, requires handyman services that are reliable and trustworthy. We specialize in home repairs, improvements, and general maintenance to keep your home in perfect shape.",
    bottomDescription:
      "Looking for a reliable <strong>handyman service in Laguna Hills</strong>, CA? HubPro.US is your trusted local expert for all your home repair and maintenance needs. From quick fixes to major installations, our skilled team delivers top-quality craftsmanship with a personal touch. Located in the heart of Laguna Hills, known for its picturesque parks and vibrant community, HubPro.US ensures your home remains in top condition with our prompt, professional services. Contact us today for a free quote and experience the HubPro difference!",
  },
  lagunaNiguel: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Laguna Niguel</span>. Laguna Niguel is known for its scenic views and luxurious living. We provide professional handyman services to ensure your home remains beautiful and functional with top-notch repairs and maintenance.",
    bottomDescription:
      "Looking for a trusted <strong>handyman in Laguna Niguel</strong>, CA? HubPro.US is your go-to solution for all home repairs, maintenance, and improvement needs in this beautiful Orange County city. Known for its picturesque hills and charming suburban feel, Laguna Niguel residents deserve top-notch services. Whether it's appliance installation, plumbing, electrical work, or custom projects, we offer professional, reliable, and affordable handyman services. Our team is dedicated to delivering quick, efficient, and high-quality results, ensuring your home stays in excellent shape. Choose HubPro.US for your next handyman project and experience the difference in Laguna Niguel!",
  },
  danaPoint: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Dana Point</span>. Dana Point, with its coastal beauty and marina lifestyle, needs handyman services that reflect the unique needs of its residents. We offer reliable home repairs and maintenance that match the coastal atmosphere.",
    bottomDescription:
      "Looking for reliable <strong>handyman services in Dana Point</strong>, CA? HubPro.US offers expert home repairs and maintenance tailored to the unique needs of this coastal community. Known for its stunning marina and oceanfront views, Dana Point residents require services that complement the beauty of their homes. Whether it's plumbing, electrical work, or home improvements, our skilled team provides professional, efficient, and affordable solutions. Let HubPro.US handle your home repair needs and experience the difference in Dana Point!",
  },
  lakeForest: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Lake Forest</span>. Lake Forest, a peaceful city surrounded by nature, deserves expert handyman services. We offer home repairs, maintenance, and installation solutions that cater to the needs of Lake Forest homeowners.",
    bottomDescription:
      "Looking for reliable <strong>handyman services in Lake Forest</strong>, CA? HubPro.US is your trusted local expert for all home repair and maintenance needs in this scenic city, known for its beautiful parks and friendly community. Whether you're in need of appliance installations, home repairs, or routine maintenance, our team is ready to help. With a commitment to quality and customer satisfaction, we offer professional, affordable, and efficient handyman solutions right in your neighborhood. Trust HubPro.US to keep your Lake Forest home in top shape, from ceiling fan installations to plumbing and beyond. Let us handle the hard work for you!",
  },
  missionViejo: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Mission Viejo</span>. Mission Viejo, a city known for its serene environment and family-friendly atmosphere, requires reliable handyman services. We offer expert home repairs and maintenance to ensure your home stays in top condition.",
    bottomDescription:
      "Looking for a trusted <strong>handyman in Mission Viejo</strong>, CA? At HubPro.US, we specialize in providing expert handyman services to residents of this beautiful city known for its scenic parks and family-friendly atmosphere. Whether you need home repairs, appliance installation, plumbing, or maintenance services, our skilled craftsmen are ready to help. We’re proud to serve the Mission Viejo community with fast, reliable, and affordable solutions. From the heart of the city to the quiet neighborhoods, HubPro.US ensures your home stays in top shape. Call today for your free quote and experience the HubPro difference in Mission Viejo!",
  },
  newportBeach: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Newport Beach</span>. Newport Beach, known for its luxury homes and stunning coastline, requires top-tier handyman services. We offer professional home repairs and improvements to maintain the elegance of your property.",
    bottomDescription:
      "HubPro.US offers top-notch <strong>handyman services in Newport Beach</strong>, CA, a city known for its stunning coastal views and vibrant lifestyle. Whether you’re a local resident or enjoy the scenic beauty of this seaside paradise, our family-owned business provides reliable solutions for all your home maintenance, repairs, and improvement needs. From appliance installation to plumbing, electrical work, and custom projects, we pride ourselves on delivering expert craftsmanship with a personal touch. With years of experience serving the Newport Beach community, HubPro.US ensures your home stays in perfect condition, so you can enjoy the best of beachside living.",
  },
  ranchoSantaMargarita: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Rancho Santa Margarita</span>. Rancho Santa Margarita, with its suburban charm and close-knit community, deserves reliable handyman services. Whether it's home repairs or maintenance, we provide the best solutions for Rancho Santa Margarita residents.",
    bottomDescription:
      "Welcome to HubPro.US, your trusted <strong>handyman service in Rancho Santa Margarita</strong>, CA. Nestled in the heart of beautiful Orange County, Rancho Santa Margarita is known for its picturesque landscapes and vibrant community. Whether you need home repairs, appliance installations, or expert maintenance, HubPro.US provides reliable, professional handyman services to keep your home in top shape. Our team is dedicated to offering fast, efficient solutions with a focus on quality. Serving Rancho Santa Margarita and its surrounding areas, we are the go-to choice for all your home improvement needs. Call today for a free quote!",
  },
  ranchoMissionViejo: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>Rancho Mission Viejo</span>. Rancho Mission Viejo, a community with a rich agricultural history, needs a handyman service that values quality and reliability. We provide expert home repairs and maintenance tailored to the needs of Rancho Mission Viejo.",
    bottomDescription:
      "Welcome to HubPro.US, your trusted family-owned <strong>handyman service in Rancho Mission Viejo</strong>, CA! Nestled in the heart of South Orange County, Rancho Mission Viejo is known for its stunning hillside views, unique modern homes, and vibrant community. Whether you need home repairs, appliance installation, or routine maintenance, HubPro.US is here to help. Our skilled team specializes in providing top-notch handyman services with the highest level of care and professionalism. Proudly serving Rancho Mission Viejo, we offer reliable, same-day service tailored to your needs. Get a free quote today and discover why we’re the go-to handyman service for your home!",
  },
  sanClemente: {
    description:
      "Hi, I'm <strong>Roman</strong>, and welcome to <strong>HubPro.US</strong>, your trusted handyman service in <span style={{ color: 'orange', fontWeight: 'bold' }}>San Clemente</span>. San Clemente, with its surf culture and beautiful beaches, needs handyman services that can keep up with an active lifestyle. We provide reliable home repairs and maintenance for the residents of San Clemente.",
    bottomDescription:
      "Looking for reliable <strong>handyman services in San Clemente</strong>, CA? HubPro.US is your go-to family-owned handyman service, proudly serving this beautiful coastal city. Known for its stunning beaches and relaxed vibe, San Clemente deserves a trustworthy team that can handle everything from home repairs to appliance installations. Whether you’re enjoying a day at the beach or relaxing at home, let us take care of your repairs, maintenance, and improvements. From plumbing fixes to electrical help, our experienced team is ready to assist. Contact HubPro.US for all your handyman needs in San Clemente — where quality service meets local charm.",
  },
};
