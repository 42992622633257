import React, { useState } from "react";
import GetQuoteTV from "../quote/GetQuoteTV";
import ReviewsTV from '../reviews/ReviewsTV';
import GetQuoteForm from '../quote/GetQuoteTVMount';
import Modal from 'react-modal';

import ImageSliderTv from "../sliders/ImageSliderTv";
import ImageSliderTvOffice from "../sliders/tv/ImageSliderTVMountOffice";
import ImageSliderTvMaster from "../sliders/tv/ImageSliderTVMountMaster";
import ImageSliderTvTools from "../sliders/tv/ImageSliderTVMountTools";
import ImageSliderTvWork from "../sliders/tv/ImageSliderTVMountWork";
import ImageSliderTvWork2 from "../sliders/tv/ImageSliderTVMountWork_2";
import ImageSliderTvWork3 from "../sliders/tv/ImageSliderTVMountWork_3";

import TV32 from "../../assets/image/tv_mounting_59.png";
import TV60 from "../../assets/image/tv_mounting_60.png";
import Check from "../../assets/tv_installation_400/tv_mounting_service_logo.webp";

function TvMounting() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (serviceName) => {
    setSelectedService(serviceName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setModalIsOpen(false);
  };

  const handleSubmit = (formData) => {
    // Здесь можно добавить логику отправки данных формы
    console.log('Form data submitted from GetQuoteForm:', formData);
    closeModal(); // Закрытие модального окна после отправки формы
  };


  const city = "Orange County"

  const oldPrice32 = "$189";
  const newPrice32 = "Only $159";

  const oldPrice60 = "$249";
  const newPrice60 = "Only $189";

  return (
    <section className="features" id="tv_mounting_service">
      <div className="container">
        <div className="container_tv">

<div className="mobile-container">
        
<div className="image-container">
  <div className="image-wrapper">
    <img onClick={() => openModal()} src={TV32} alt="59 TV Mouning Service" className="custom-image" />
    <p className="image-caption">
      32"-59"&nbsp; 
      <span className="old-price">{oldPrice32}</span>
      <br />
      <br />
      <span className="new-price-wrapper">
        <span className="new-price">{newPrice32}</span>
      </span>
    </p>
  </div>

  <div className="image-wrapper">
    <img onClick={() => openModal()} src={TV60} alt="60 TV Mouning Service" className="custom-image" />
    <p className="image-caption">
      60" or larger&nbsp;
      <span className="old-price">{oldPrice60}</span>
      <br />
      <br />
      <span className="new-price-wrapper">
        <span className="new-price">{newPrice60}</span>
      </span>
    </p>
  </div>

</div>


<div className="text-block-tv">
    <p>
      *Prices are based on drywall installations without wire concealment or additional equipment setup. 
      Please ensure you have a compatible <strong>TV mount</strong>. If not, we can source the best mounts tailored to your needs and preferences based on our expertise.
    </p>
  </div>
  </div>



        <div className="mobile-container">
        <div>
            <ul className="tv_mounting_skills_list">
              <p className="tv_mounting_skills">No Hidden Fees</p>
              <p className="tv_mounting_skills">100% Satisfaction Guaranteed</p>
              <p className="tv_mounting_skills">Backed by 1 Year Warranty</p>
              <p className="tv_mounting_skills">Flexible Scheduling</p>
            </ul>

          </div>
        </div>



        <div className="mobile-container">
      <h2 className="about__h1">
      Pro TV Mount Service in Orange County
          </h2>
          <br />
          <ImageSliderTvMaster />
          <br></br>
          <br></br>
          </div>

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
            Book My Service
          </a>
          <br></br>
        </div>



      <div className="mobile-container">
      <ReviewsTV />
      <br></br>
      <br></br>
      </div>


      <div className="mobile-container">
      <h2 className="about__h1">
      TV Wire In-Wall Concealment
          </h2>
          <br />
          <ImageSliderTvOffice />
          <br></br>
          <br></br>
          </div>

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
           Book My Service
          </a>
          <br></br>
        </div>



        <div className="mobile-container">
      <h2 className="about__h1">
      My TV Mount Installation Service
          </h2>
          <br />
          <ImageSliderTvWork />
          <br></br>
          <br></br>
          </div>


          <br />
          <ImageSliderTvWork2 />
          <br></br>
          <br></br>


          <br />
          <ImageSliderTvWork3 />
          <br></br>
          <br></br>


          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
           Book My Service
          </a>
          <br></br>
        </div>



        <div className="mobile-container">
      <h2 className="about__h1">
      My Pro Tools For TV Installation Service in Orange County
          </h2>
          <br />
          <ImageSliderTvTools />
          <br></br>
          <br></br>
          </div>

          <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            onClick={() => openModal()}
          >
           Book My Service
          </a>
          <br></br>
        </div>




          <div className="container_tv_qoute_form">
            <br></br>
            <GetQuoteTV />
            <br></br>
            <br></br>
          </div>

          <div className="mobile-container">
          <div className="text">
            <h3>Professional Installation of Any TV Type in {city}</h3>
            <p>
              We specialize in mounting TVs of various types and sizes,
              including OLED, LED, LCD, and plasma screens. Regardless of your
              TV model or size, our experienced specialists will ensure flawless
              installation.
            </p>
            <h3>Safety and Reliability</h3>
            <p>
              Your precious TVs are in safe hands. We strictly adhere to all
              safety standards and use only quality equipment for mounting. Your
              TV will be securely installed without the risk of falling.
            </p>

            <h3>Seamless Installation Experience</h3>
            <p>
              Unlike aggregators, we provide a hands-on, personalized service
              tailored to your specific needs. You'll benefit from direct
              communication with our experienced specialists, ensuring a smooth
              and seamless TV installation process.
            </p>

            <h3>Personalized Approach</h3>
            <p>
              We understand that every TV installation is unique. That's why we
              offer a personalized approach to each project, considering your
              preferences and the specifics of your space. We'll make sure your
              TV looks perfect in your interior.
            </p>
            <h3>Expert Consultation and Support</h3>
            <p>
              Our team of experts is always ready to provide you with
              professional consultation on TV installation. We'll answer all
              your questions and provide full support from choosing the optimal
              placement to completing the installation process.
            </p>

            <img
              src={Check}
              alt="Expertise Icon"
              style={{
                height: "350px",
                marginRight: "40px",
                borderRadius: "10px",
              }}
            />

            <h3>Supporting Our {city} Local Community</h3>
            <p>
              We're a local {city} business, and choosing us helps our
              community thrive. Aggregators are often large corporations, not
              always attuned to local needs.
            </p>
            <h3>Customer Satisfaction Guaranteed</h3>
            <p>
              Our main goal is your complete satisfaction. We strive to exceed
              your expectations in all aspects of our work, providing
              high-quality service and outstanding results. Your trust is our
              greatest reward.
            </p>
            <h3>Competitive Prices and Flexible Schedule</h3>
            <p>
              We offer competitive prices for our services and a flexible work
              schedule to make the TV installation process as convenient and
              accessible for you as possible. We aim to make your life easier by
              offering high-quality services at affordable prices.
            </p>
          </div>
        </div>
        <br></br>
        <div className="mobile-only">
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonTvPage"
            href="sms:949-438-0343"
          >
            Book NOW!
          </a>
        </div>
        </div>
      </div>


      <div className="mobile-container">
          <ImageSliderTv />
          <br></br>
          <br></br>
          </div>

      

      <div className="get-quote">
        <div className="get-quote__container container">
          <div className="get-quote__p">
            Elevate Your Living Space with Expert {city} TV
            Mounting Services!
          </div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonTvPage"
            href="sms:+19494380343"
          >
            Text Us 24/7
          </a>
        </div>
      </div>



      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Get a Free Quote Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: '50%',
            background: 'linear-gradient(to bottom, #0090ea, #0064cc)', // Исправлено здесь
            left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      maxWidth: '600px', // Можно настроить ширину модального окна
      maxHeight: '1600px', // Можно настроить максимальную высоту модального окна
      overflowY: 'auto', // Разрешить прокрутку содержимого, если оно не помещается
      borderRadius: '10px', // Закругление углов
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Тень
      border: 'none' // Убрать окантовку
          }
        }}
      >
        <h5 className="banner-quote__h1">Book TV Mount Service</h5>
        <GetQuoteForm handleSubmit={handleSubmit} />
      </Modal>
    </section>
  );
}

export default TvMounting;