import React from 'react';
import { useParams } from 'react-router-dom';
import articles from '../../data/articles'; // Импортируем данные статей

const ArticlePage = () => {
  const { slug } = useParams();

  const article = articles.find((article) => article.slug === slug);

  if (!article) {
    return <h1>Article not found</h1>;
  }

  return (
    <div className="article-page">
      <h1>{article.title}</h1>
      <p>{article.content}</p>
      <small>{article.date}</small>
    </div>
  );
};

export default ArticlePage;