import React from "react";
import "../index.css";

function Footer() {
  const city = "Orange County";

  return (
    <section>
      <footer class="footer">
        <div class="footer__container container">
          <div class="footer__blocks">
            <div class="footer__block">
              <h3 class="footer__block-title">ABOUT US</h3>
              <p class="footer__block-content">
                The HubPro best Furniture Assembly & TV Mounting service in{" "}
                {city} offers expert furniture assembly and TV mounting. We
                specialize in assembling furniture of any size and type, along
                with fast and efficient TV installations.
              </p>
            </div>
            <div class="footer__block">
              <h3 class="footer__block-title">SERVICES</h3>
              <ul class="footer__block-list">
                <li>
                  <a href="/handyman_services">Handyman Services</a>
                </li>

                <li>
                  <a href="/painters-near-me">Painting Services</a>
                </li>

                <li>
                  <a href="/furniture_assembly">Furniture Assembly Services</a>
                </li>

                <li>
                  <a href="/tv_mounting">TV Mounting Services</a>
                </li>
              </ul>
            </div>
            <div class="footer__block">
              <h3 class="footer__block-title">SUPPORT</h3>
              <ul class="footer__block-list">
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
                <li>
                  <a href="/safety-support">Safety & Support</a>
                </li>
                <li>
                  <a href="/cookie-policy">Cookie Policy</a>
                </li>
              </ul>
            </div>
            <div class="footer__block">
              <h3 class="footer__block-title">CONTACT US</h3>
              <ul class="footer__block-list">
                <li>HubPro.US</li>
                <li>
                  <a href="tel:+19494380343" id="phoneButtonFooter">
                    (949) 438-0343
                  </a>
                </li>
                <li>
                  <a href="mailto:info@hubpro.us">info@hubpro.us</a>
                </li>
                {/*<li>*/}
                {/*  5121 Molino,<br></br> Irvine, CA 92618*/}
                {/*</li>*/}
                <li>
                  <a
                    target="_blank"
                    href="https://maps.app.goo.gl/ZMgqtZezotNh9Tau6"
                  >
                    Google Maps
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p class="footer__disclaimer" id="disclaimer">
            Professional Furniture Assembly & TV Mounting in {city}. Offering
            expert furniture assembly and TV mounting services across {city}.
            Our skilled technicians ensure swift and precise assembly, saving
            you time and effort. Important: We are not licensed contractors
            pursuant to Section 7027.2 of the Business and Professions Code of
            the State of California. Serving Aliso Viejo, Anaheim, Brea, Buena
            Park, Costa Mesa, Cypress, Dana Point, Fountain Valley, Fullerton,
            Garden Grove, Huntington Beach, Irvine, La Habra, La Palma, Laguna
            Beach, Laguna Hills, Laguna Niguel, Laguna Woods, Lake Forest, Los
            Alamitos, Mission Viejo, Newport Beach, Orange, Placentia, Rancho
            Santa Margarita, San Clemente, San Juan Capistrano, Santa Ana, Seal
            Beach, Stanton, Tustin, Villa Park, Westminster, Yorba Linda.
          </p>
          <div>
            <p class="footer__legal-text" style={{ color: "grey" }}>
              © Copyright 2025 by{" "}
              <a href="https://hubpro.us/" style={{ textDecoration: "none" }}>
                HubPro.US
              </a>{" "}
              All rights reserved. {city}
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Footer;
